<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <div class="animated fadeIn">
      <b-form @submit.prevent="onSubmit" novalidate>
        <b-card-header>
          Create Currency Control
          <div class="card-header-actions">
            <a
              href="currency-control"
              class="card-header-action"
              rel="noreferrer noopener"
            >
              <small class="text-muted">Back</small>
            </a>
          </div>
        </b-card-header>
        <b-card>
          <b-row class="mb-4">
            <!-- Active Date -->
            <b-col class="mb-4" sm="12">
              <h6>Active Date</h6>
              <b-form-group class="mb-3" label-for="daterange">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <i class="fa fa-calendar"></i>
                  </b-input-group-prepend>
                  <b-form-input
                    type="text"
                    name="activeAt"
                    placeholder="Select Active Date"
                    v-model="form.activeAt"
                  >
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- Max Threshold -->
            <b-col class="mb-4" sm="6">
              <h6>
                Top Threshold USD-IDR
              </h6>
              <b-input-group>
                <b-form-input
                  autofocus
                  size="md"
                  type="text"
                  id="currencyMax"
                  v-model="form.currencyMax"
                  aria-describedby="currencyMax"
                  placeholder="Input top threshold"
                  :state="chkState('currencyMax')"
                  @blur="validateField('currencyMax')"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <!-- Min Threshold -->
            <b-col class="mb-4" sm="6">
              <h6>
                Bottom Threshold USD-IDR
              </h6>
              <b-input-group>
                <b-form-input
                  autofocus
                  size="md"
                  type="text"
                  id="currencyMin"
                  v-model="form.currencyMin"
                  aria-describedby="currencyMin"
                  placeholder="Input bottom threshold"
                  :state="chkState('currencyMin')"
                  @blur="validateField('currencyMin')"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <!-- OTP Code -->
            <b-col class="mb-4" sm="12">
              <h6>
                OTP Code
              </h6>
              <b-input-group>
                <b-form-input
                  autofocus
                  size="md"
                  type="number"
                  id="otpCode"
                  v-model="form.otpCode"
                  aria-describedby="otpCode"
                  placeholder="Insert OTP Code"
                  :state="chkState('otpCode')"
                  @blur="validateField('otpCode')"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    type="button"
                    variant="secondary"
                    class="btn-otp"
                    :disabled="stateButtonOtp"
                    @click="getOtpCode"
                  >
                    <div
                      class="sk-three-bounce loading-btn"
                      v-if="isLoadingOTP"
                    >
                      <div class="sk-child sk-bounce1"></div>
                      <div class="sk-child sk-bounce2"></div>
                      <div class="sk-child sk-bounce3"></div>
                    </div>
                    <p v-else>
                      {{ buttonTextOtp }}
                    </p>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <span
                class="helper-text-invalid"
                v-if="$v.form.otpCode.$dirty && !$v.form.otpCode.validationOTP"
              >
                OTP code must contain 6 digits
              </span>
            </b-col>
          </b-row>
          <!-- Action Submit -->
          <b-button
            type="submit"
            variant="primary"
            :disabled="!this.$v.$invalid && this.warning_message ? false : true"
          >
            Submit
          </b-button>
        </b-card>
      </b-form>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required, helpers } from "vuelidate/lib/validators";
const validationCurrency = helpers.regex(
  "validationCurrency",
  /^(?!0(\d|,|\.\d{1,2})*$)(?:[1-9]\d{0,2}(?:\.\d{3})*|\d+)(?:,\d{1,2})?$/
  // /^(?:\d{1,3}(?:\.\d{3})*|\d+)(?:,\d{1,2})?$/
);
const otpRegex = /^-?\d{6}$/;

export default {
  name: "create-currency-control",
  components: {},
  mixins: [validationMixin],
  validations: {
    form: {
      activeAt: {
        required
      },
      currencyMax: {
        required,
        validationCurrency
      },
      currencyMin: {
        required,
        validationCurrency
      },
      otpCode: {
        required,
        validationOTP: helpers.regex("validationOTP", otpRegex)
      }
    }
  },
  computed: {
    formStr() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
    invCheck() {
      return "You must accept before submitting";
    },
    stateButtonOtp() {
      return this.countdown > 0 || this.isLoadingOTP;
    },
    buttonOTPText() {
      return this.countdown > 0 ? `${this.countdown} detik` : "GET OTP";
    }
  },
  data() {
    return {
      countdown: 0,
      isButtonOtp: false,
      buttonTextOtp: "Get OTP Code",
      countdownInterval: null,
      startDate: "",
      endDate: "",
      form: {
        otpCode: "",
        activeAt: "",
        currencyMax: "",
        currencyMin: ""
      },
      isLoading: false,
      isLoadingOTP: false,
      warning_message: true,
      errors: {
        code: "",
        message: "",
        status: ""
      }
    };
  },
  created() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  },
  methods: {
    chkState(val) {
      const field = this.$v.form[val];
      return !field.$dirty || !field.$invalid;
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some(child => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
    handleLoadError(error) {
      const code = error.response.data.meta.code;
      const message = error.response.data.meta.message;

      this.isLoading = false;
      this.isLoadingOTP = false;
      this.errors.code = code;
      this.errors.message = message;
      this.errors.status = code;

      if (code == 401) {
        if (localStorage.getItem("access_token") != null) {
          localStorage.removeItem("access_token");
          this.$swal
            .fire(
              "Your session expired!",
              "Your session has expired. Please login again to access this page!",
              "error"
            )
            .then(() => {
              this.$router.push("/login");
            });
        }
      } else if (code == 403) {
        this.$router.push("/403");
      } else {
        this.$swal.fire("Failed!", message, "error");
      }
    },
    handleParseTreshold(val) {
      return val.replace(".", "").replace(",", ".");
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.warning_message) {
        const payload = {
          active_at: moment(this.form.activeAt).format("YYYY-MM-DD HH:mm:ss"),
          currency_threshold_min: this.handleParseTreshold(
            this.form.currencyMin
          ),
          currency_threshold_max: this.handleParseTreshold(
            this.form.currencyMax
          ),
          otp_code: this.form.otpCode
        };

        this.isLoading = true;
        this.$http
          .post(`currency-threshold`, payload)
          .then(res => {
            if (res) {
              this.$toasted.show(
                `<i class="fa fa-check-circle" aria-hidden="true"></i> Currency threshold added`,
                {
                  theme: "toasted-primary",
                  position: "top-center",
                  className: "custom-toast",
                  duration: 4000
                }
              );

              setTimeout(() => {
                this.$router.push({ name: "Currency Control" });
              }, 2000);
            }
          })
          .catch(error => {
            this.handleLoadError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    validateField(field) {
      this.$v.form[field].$touch();
    },
    getOtpCode() {
      if (!this.isButtonOtp) {
        this.isLoadingOTP = true;

        this.$http
          .get("/currency-threshold-request-otp")
          .then(res => {})
          .catch(err => {
            this.handleLoadError(err);
          })
          .finally(() => {
            this.isLoadingOTP = false;
            this.countdown = 60;
            this.isButtonOtp = true;
            this.buttonTextOtp = `${this.countdown}s`;

            this.countdownInterval = setInterval(() => {
              if (this.countdown > 0) {
                this.countdown--;
                this.buttonTextOtp = `${this.countdown}s`;
              } else {
                clearInterval(this.countdownInterval);
                this.isButtonOtp = false;
                this.buttonTextOtp = "Get OTP Code";
              }
            }, 1000);
          });
      }
    }
  },
  mounted() {
    const onDateSelected = (start, end, inputName) => {
      const newValue = start.format("YYYY-MM-DD HH:mm:ss");

      this.form.activeAt = newValue;

      $(`input[name="${inputName}"]`).data(
        "daterangepicker"
      ).autoUpdateInput = true;
    };

    $(async function() {
      const today = moment().toDate();
      const datePicker = $('input[name="activeAt"]');

      // Datepicker
      datePicker.daterangepicker(
        {
          singleDatePicker: true,
          alwaysShowCalendars: true,
          autoUpdateInput: false,
          readonly: true,
          drops: "auto",
          minDate: today,
          timePicker: true,
          timePickerSeconds: true,
          locale: {
            applyLabel: "Apply",
            format: "YYYY-MM-DD HH:mm:ss"
          }
        },
        function(start, end) {
          onDateSelected(start, end, "activeAt");
        }
      );
    });
  }
};
</script>

<style>
.submt {
  width: 100%;
  height: auto;
  display: block;
}

.btn-otp {
  width: 100px;
}
</style>
<style>
.btn.disabled {
  background: #dfdfdf !important;
  color: grey !important;
  border-color: #dfdfdf !important;
}

.form-control {
  height: 36.22px;
}

.text-red {
  color: #cb211f;
}
.text-success {
  color: #35aa4b;
}

.helper-text-invalid {
  margin-top: 4px;
  font-size: 11px;
  color: red;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.sk-three-bounce.loading-btn {
  margin: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.sk-three-bounce.loading-btn .sk-child {
  width: 8px;
  height: 8px;
  opacity: 0.6;
}

/* Pastikan ini ada di file CSS global atau Vue dengan <style scoped> */
.custom-toast {
  padding: 12px 16px !important;
  display: flex;
  align-items: center;
  color: black !important;
  font-size: 14px !important;
  border-radius: 6px !important;
  border: 1px solid #e5e7eb !important;
  background-color: white !important;
}

/* Styling untuk ikon di dalam toast */
.custom-toast i {
  color: green !important; /* Ubah warna ikon menjadi hijau */
  margin-right: 8px;
  font-size: 18px;
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
